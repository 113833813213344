var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bgimg bgimg-main"},[_c('v-container',[_c('v-row',{staticClass:"pa-12 text-center mx-auto meh",attrs:{"justify":"space-around"}},[_c('v-dialog',{attrs:{"persistent":"","transition":"dialog-bottom-transition","max-width":"700"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('v-card',{staticClass:"mx-auto",attrs:{"loading":_vm.admincode.length == 12}},[_c('v-card-title',{staticClass:"text-h6 font-weight-regular justify-space-between"},[_c('span',[_vm._v("Admin code")])]),_c('v-card-text',[_c('v-otp-input',{attrs:{"value":_vm.admincode,"length":"12"},on:{"finish":_vm.login}}),_c('transition',{attrs:{"name":_vm.errormessage
                    ? 'scroll-y-reverse-transition'
                    : 'scroll-y-transition'}},[_c('v-alert',{directives:[{name:"show",rawName:"v-show",value:(_vm.errormessage),expression:"errormessage"}],staticClass:"mt-4",attrs:{"dense":"","outlined":"","type":"error"}},[_vm._v(" "+_vm._s(_vm.errormessage)+" ")])],1)],1)],1)]},proxy:true}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}}),_c('v-btn',{staticClass:"mx-4",attrs:{"fab":"","fixed":"","depressed":"","bottom":"","right":"","loading":_vm.loading},on:{"click":_vm.refresh}},[_c('v-icon',[_vm._v("mdi-cached")])],1),_c('div',{staticClass:"pb-6 w100p"},[_c('v-sheet',{attrs:{"width":"100%","elevation":"4"}},[_c('v-card',{attrs:{"loading":_vm.loading}},[_c('v-card-title',[_c('v-text-field',{staticClass:"pt-0",attrs:{"prepend-icon":"mdi-magnify","label":"Filter...","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1)],1),_vm._l((_vm.categories),function(cat){return _c('div',{key:cat.wave,staticClass:"pb-6 w100p"},[_c('v-sheet',{attrs:{"rounded":"xl","width":"100%","elevation":"4"}},[_c('v-card',{attrs:{"loading":_vm.loading}},[_c('v-subheader',[_vm._v(_vm._s(cat.title))]),_c('v-data-table',{attrs:{"headers":_vm.getHeaders(cat),"items":_vm.getGuests(cat),"search":_vm.search,"disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.num",fn:function(ref){
                    var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","depressed":""},on:{"click":function($event){$event.stopPropagation();return _vm.decrement(item)}}},[_c('v-icon',[_vm._v(" mdi-minus ")])],1),_c('span',{staticClass:"px-2"},[_vm._v(" "+_vm._s(item.numaccepted)+" / "+_vm._s(item.invitees)+" ")]),_c('v-btn',{attrs:{"icon":"","depressed":""},on:{"click":function($event){$event.stopPropagation();return _vm.increment(item)}}},[_c('v-icon',[_vm._v(" mdi-plus ")])],1)]}},{key:"item.lastupdated",fn:function(ref){
                    var item = ref.item;
return [(item.lastupdated)?_c('span',[_vm._v(_vm._s(new Date(item.lastupdated).toDateString()))]):_vm._e()]}},{key:"item.action",fn:function(ref){
                    var item = ref.item;
return [_c('transition',{attrs:{"name":item.numaccepted != item.originalNumaccepted
                      ? 'scroll-x-reverse-transition'
                      : 'scroll-x-transition'}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(item.numaccepted != item.originalNumaccepted),expression:"item.numaccepted != item.originalNumaccepted"}]},[_c('v-btn',{attrs:{"color":"primary","depressed":"","loading":item.loading},on:{"click":function($event){return _vm.save(item)}}},[_vm._v(" Opslaan ")])],1)])]}},{key:"no-data",fn:function(){return [_c('span',{staticClass:"text--secondary"},[_vm._v(" Geen informatie beschikbaar ")])]},proxy:true}],null,true)})],1)],1)],1)})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }