<template>
  <div class="bgimg bgimg-main">
    <v-container>
      <v-row justify="space-around" class="pa-12 text-center mx-auto meh">
        <v-dialog
          persistent
          v-model="dialog"
          transition="dialog-bottom-transition"
          max-width="700"
        >
          <template v-slot:default>
            <v-card class="mx-auto" :loading="admincode.length == 12">
              <v-card-title
                class="text-h6 font-weight-regular justify-space-between"
              >
                <span>Admin code</span>
              </v-card-title>
              <v-card-text>
                <v-otp-input
                  :value="admincode"
                  length="12"
                  @finish="login"
                ></v-otp-input>

                <transition
                  :name="
                    errormessage
                      ? 'scroll-y-reverse-transition'
                      : 'scroll-y-transition'
                  "
                >
                  <v-alert
                    dense
                    class="mt-4"
                    outlined
                    v-show="errormessage"
                    type="error"
                  >
                    {{ errormessage }}
                  </v-alert>
                </transition>
              </v-card-text>
            </v-card>
          </template>
        </v-dialog>
        <v-btn
          class="mx-4"
          fab
          fixed
          depressed
          bottom
          right
          :loading="loading"
          @click="refresh"
        >
          <v-icon>mdi-cached</v-icon>
        </v-btn>
        <div class="pb-6 w100p">
          <v-sheet width="100%" elevation="4">
            <v-card :loading="loading">
              <v-card-title>
                <v-text-field
                  v-model="search"
                  prepend-icon="mdi-magnify"
                  label="Filter..."
                  single-line
                  hide-details
                  class="pt-0"
                ></v-text-field>
              </v-card-title>
            </v-card>
          </v-sheet>
        </div>

        <div v-for="cat in categories" :key="cat.wave" class="pb-6 w100p">
          <v-sheet rounded="xl" width="100%" elevation="4">
            <v-card :loading="loading">
              <v-subheader>{{ cat.title }}</v-subheader>
              <v-data-table
                :headers="getHeaders(cat)"
                :items="getGuests(cat)"
                :search="search"
                disable-pagination
                hide-default-footer
              >
                <template v-slot:item.num="{ item }">
                  <v-btn icon depressed @click.stop="decrement(item)">
                    <v-icon> mdi-minus </v-icon>
                  </v-btn>
                  <span class="px-2">
                    {{ item.numaccepted }} / {{ item.invitees }}
                  </span>
                  <v-btn icon depressed @click.stop="increment(item)">
                    <v-icon> mdi-plus </v-icon>
                  </v-btn>
                </template>

                <template v-slot:item.lastupdated="{ item }">
                  <span v-if="item.lastupdated">{{
                    new Date(item.lastupdated).toDateString()
                  }}</span>
                </template>

                <template v-slot:item.action="{ item }">
                  <transition
                    :name="
                      item.numaccepted != item.originalNumaccepted
                        ? 'scroll-x-reverse-transition'
                        : 'scroll-x-transition'
                    "
                  >
                    <div v-show="item.numaccepted != item.originalNumaccepted">
                      <v-btn
                        color="primary"
                        depressed
                        @click="save(item)"
                        :loading="item.loading"
                      >
                        Opslaan
                      </v-btn>
                    </div>
                  </transition>
                </template>
                <template v-slot:no-data>
                  <span class="text--secondary">
                    Geen informatie beschikbaar
                  </span>
                </template>
              </v-data-table>
            </v-card>
          </v-sheet>
        </div>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "Admin",

  data: () => ({
    admincode: window.localStorage.getItem("admincode") || "",
    loading: false,
    search: "",
    errormessage: "",
    dialog: true,
    categories: [
      {
        wave: 1,
        title: "Daggasten",
      },
      {
        wave: 2,
        title: "Avondgasten",
      },
    ],
    // headers: [
    //   {
    //     text: "Wie",
    //     align: "start",
    //     value: "name",
    //     width: "25%",
    //   },
    //   { text: "Aantal Aangemeld", sortable: false, width: "25%", value: "num" },
    //   { text: "Laatst Bijgewerkt", width: "25%", value: "lastupdated" },
    //   { text: "Actie", width: "25%", sortable: false, value: "action" },
    // ],
    guests: [],
  }),

  mounted() {
    if (this.admincode) {
      this.login(this.admincode);
    }
  },

  methods: {
    getGuests(cat) {
      return this.guests.filter((el) => el.wave == cat.wave);
    },
    getHeaders(cat) {
      let guests = this.getGuests(cat);
      let totalSignups = 0;
      let numSignups = 0;
      let totalHouseholds = 0;
      let numHouseholds = 0;

      for (let guest of guests) {
        totalHouseholds++;

        if (guest.lastupdated != null) {
          numHouseholds++;
        }

        totalSignups += guest.invitees;
        numSignups += guest.numaccepted;
      }

      return [
        {
          text: "Wie",
          align: "start",
          value: "name",
          width: "25%",
        },
        {
          text: `Aantal Aangemeld (${numSignups}/${totalSignups})`,
          sortable: false,
          width: "25%",
          value: "num",
        },
        { text: `Laatst Bijgewerkt (${numHouseholds}/${totalHouseholds})`, width: "25%", value: "lastupdated" },
        { text: "Actie", width: "25%", sortable: false, value: "action" },
      ];
    },
    refresh() {
      const _this = this;

      this.loading = true;
      setTimeout(() => {
        _this.login(_this.admincode);
      }, 250 + Math.random() * 2000);
    },
    login(e) {
      var data = new FormData();
      data.append("admincode", e);

      const _this = this;

      fetch("/api/admin/list", {
        method: "POST",
        body: data,
      })
        .then((res) => res.json())
        .then(function (data) {
          if (data.error) {
            _this.errormessage = data.error.message;
          } else {
            _this.errormessage = "";

            let guests = Object.values(data.signups);
            guests.sort((a, b) => (a.name > b.name ? 1 : -1));

            _this.guests = [];
            for (let guest of guests) {
              guest.loading = false;
              guest.originalNumaccepted = guest.numaccepted;
              _this.guests.push(guest);
            }

            _this.admincode = e;
            _this.dialog = false;
            _this.loading = false;
            window.localStorage.setItem("admincode", e);
          }
        });
    },
    increment(item) {
      item.numaccepted += 1;
    },
    decrement(item) {
      if (item.numaccepted > 0) {
        item.numaccepted -= 1;
      }
    },
    save(item) {
      var data = new FormData();
      data.append("admincode", this.admincode);
      data.append("signupcode", item.signupcode);
      data.append("numaccepted", item.numaccepted);

      item.loading = true;

      fetch("/api/admin/signup", {
        method: "POST",
        body: data,
      })
        .then((res) => res.json())
        .then(function (data) {
          item.loading = false;
          if (data.error) {
            alert(JSON.stringify(data));
          } else {
            item.lastupdated = data.lastupdated;
            item.numaccepted = Number(data.numaccepted);
            item.originalNumaccepted = Number(data.numaccepted);
          }
        });
    },
  },
};
</script>

<style scoped>
.meh {
  max-width: 800px;
  /* width: 80vw; */
}

.w100p {
  width: 100%
}

.bgimg-main {
  background-image: url("~@/assets/background.jpg");
  min-height: 100%;
}

.bgimg {
  position: relative;
  opacity: 0.65;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
</style>
